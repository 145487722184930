import { BaseSvgProps } from "./types";
const SvgFeatureCheckmark = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
  checkStroke,
  bodyFill,
}: BaseSvgProps & { bodyFill?: any; checkStroke?: any }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M12 23.53c6.154 0 11.143-4.988 11.143-11.142 0-6.154-4.989-11.143-11.143-11.143C5.846 1.245.857 6.234.857 12.388c0 6.154 4.99 11.143 11.143 11.143Z"
      fill={bodyFill || "#0FCD5B"}
      style={{ stroke: stroke || "#fff" }}
    />
    <path
      d="m6.356 13.084 2.993 3.36a.823.823 0 0 0 .648.285.857.857 0 0 0 .643-.308l7.005-8.374"
      stroke={checkStroke || "#fff"}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFeatureCheckmark;
