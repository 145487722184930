import { Combobox as HeadlessComobox } from "@headlessui/react";
import classnames from "classnames";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

const MAX_HEIGHT = 256;

const ComboboxOptions = ({ children }: PropsWithChildren) => {
  const optionsRef = useRef<HTMLUListElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const optionsTopBound =
      optionsRef.current?.getBoundingClientRect().top || 0;
    const clientHeight = window.innerHeight;
    // If available space for a component is less then its predefined max height
    // we adjust it so it does not overflow
    if (clientHeight - optionsTopBound < MAX_HEIGHT + 28) {
      setMaxHeight(clientHeight - optionsTopBound - 28 - 10);
      return;
    }
    setMaxHeight(MAX_HEIGHT);
  }, []);

  return (
    <HeadlessComobox.Options
      style={{
        maxHeight: maxHeight,
      }}
      ref={optionsRef}
      className={classnames(
        "custom-scrollbar overflow-y-auto overscroll-contain rounded border border-neutral-500 bg-neutral-50",
      )}
    >
      {children}
    </HeadlessComobox.Options>
  );
};

export default ComboboxOptions;
