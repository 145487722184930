import classnames from "classnames";
import React, { BaseHTMLAttributes } from "react";
import { BaseTypography } from "./types";

const onDarkClassName = `
  text-primary
`;

const onLightClassName = `
  text-invert-primary
`;

export type BodyProps = {
  italic?: boolean;
  bold?: boolean;
  small?: boolean;
  element?: string; // TODO safely type
  disableUI?: boolean;
  onDark?: boolean;
} & BaseTypography &
  BaseHTMLAttributes<HTMLParagraphElement>;

export const Body: React.FunctionComponent<BodyProps> = ({
  children,
  italic,
  bold,
  small,
  className,
  disableUI,
  element = "p",
  onDark,
  ...other
}) => {
  return React.createElement(
    element,
    {
      className: classnames(
        `antialiased ${className}`,
        {
          "font-normal": !bold,
          "font-bold": bold,
          italic: italic,
          "text-base": !small,
          "text-sm": small,
          "font-ui": !disableUI,
          "font-headings": disableUI,
        },
        {
          [onDarkClassName]: onDark,
          [onLightClassName]: !onDark,
        },
      ),
      ...other,
    },
    children,
  );
};
