"use client";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { Logo, LogoProps } from "../Logo";
import { HeaderLink, HeaderLinkProps } from "./HeaderLink";
import "./index.css";

export type HeaderProps = {
  navItems?: React.ReactNode;
  darkNav?: boolean;
  logoBgDark?: boolean;
  className?: string;
  maxWidth?: string;
  LinkComponent: React.ComponentType<any>;
  logoHref: string;
  logoProps?: LogoProps;
  logoClassName?: string;
  handleScrollExternal?: () => void;
};

export type HeaderStatic = {
  Link: React.FC<HeaderLinkProps>;
};

// todo improve usage and flexibility
const Header: React.FC<HeaderProps> & HeaderStatic = ({
  navItems,
  darkNav = false,
  logoBgDark = false,
  className,
  maxWidth = "max-w-screen-2xl",
  LinkComponent,
  logoHref = "/",
  logoProps = { base: true },
  logoClassName,
  handleScrollExternal,
}) => {
  const [triggerBlack, setTriggerBlack] = useState(darkNav);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("up");

  useEffect(() => {
    // ensure to trigger black on first load
    const hero = document.getElementById("hero");
    setTriggerBlack(window.pageYOffset >= (hero?.clientHeight || 0));

    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (handleScrollExternal) {
        handleScrollExternal();
      }
      if (!!navItems) {
        const hero = document.getElementById("hero");
        setTriggerBlack(window.pageYOffset >= (hero?.clientHeight || 0));
      }
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navItems, handleScrollExternal]);

  return (
    <header className={classnames("relative", className)}>
      <nav
        className={classnames(
          "flex h-[60px] w-full items-center justify-center p-3 lg:px-7 lg:py-3",
          {
            "fixed top-0 z-50": navItems,
            "bg-invert-primary": triggerBlack || darkNav,

            "animate-header": scrollDirection === "down",
            "animate-header-visible": scrollDirection === "up",
          },
        )}
      >
        <div
          className={classnames(
            "mx-auto flex w-full items-center justify-between gap-0 lg:gap-2.5",
            maxWidth,
          )}
        >
          <div
            className={classnames(
              // 470px - width of CTA block in product page. This could be dynamic if necessary.
              "flex w-fit items-center",
              {
                "lg:w-[450px] lg:bg-neutral-900 xl:!w-[470px]": logoBgDark,
              },
            )}
          >
            <LinkComponent
              to={logoHref}
              href={logoHref}
              className={classnames(
                logoClassName,
                "block w-[75px] min-[350px]:w-[100px] min-[376px]:w-[125px] [&>svg]:min-h-3 min-[350px]:[&>svg]:min-h-4 min-[376px]:[&>svg]:min-h-5",
              )}
              aria-label="submix"
            >
              <Logo {...logoProps} />
            </LinkComponent>
          </div>
          <div className="w-full max-w-[calc(100%-92px)] min-[350px]:max-w-[calc(100%-112px)] min-[375px]:max-w-[calc(100%-137px)] md:w-full">
            {navItems}
          </div>
        </div>
      </nav>
    </header>
  );
};
Header.Link = HeaderLink;
export { Header };
