import { BaseSvgProps } from "./types";
const SvgUser = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M12 12a5.202 5.202 0 1 0 0-10.404A5.202 5.202 0 0 0 12 12ZM21.892 22.404a10.387 10.387 0 0 0-19.784 0h19.784Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgUser;
