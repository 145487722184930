import {
  CbIcon,
  FacebookIconSmall,
  InstagramIconSmall,
  LinkedinIconSmall,
  GrayLock,
  Logo,
  MastercardIcon,
  TwitterIconSmall,
  Typography,
  VisaIcon,
  YoutubeIconSmall,
} from "@submix/component-library";
import classnames from "classnames";
import Link from "next/link";
import GetLatestNews from "./GetLatestNews";

const linksClassName = `
    flex
    flex-col
    gap-8
    max-md:gap-2
    [&>a]:w-fit
    [&>a]:!font-ui
  `;

const socialLinkClassName = `
    flex
    items-center
    gap-3
    [&>span]:!text-base
    [&>span]:!font-ui
    [&>span]:!font-semibold
    [&>:nth-child(2)]:hidden
    md:[&>:nth-child(2)]:!inline
  `;

const linkHoverClassName = `
    [&>*]:hover:!text-neutral-500
    hover:!text-neutral-500
    [&>svg>path]:hover:!fill-neutral-500
  `;

const textClassName = `
md:col-span-4 lg:col-span-2
`;

const Footer = ({
  translation: t,
  basePath = "",
  classNames,
}: {
  translation: (key: string) => string;
  basePath?: string;
  classNames?: string;
}) => {
  return (
    <footer
      className={classnames(
        "z-10 overflow-hidden bg-neutral-900 px-3 py-7 text-neutral-50 md:px-7 lg:py-24",
        classNames,
      )}
    >
      <div
        className={classnames(
          "m-auto grid w-full max-w-[1436px] ",
          "grid-rows-auto grid-cols-6 gap-x-2.5 gap-y-12",
          "md:grid-cols-12 md:grid-rows-[repeat(3,auto)] md:gap-x-7 md:gap-y-10",
          "md:grid-rows-[repeat(2,auto)] lg:grid-rows-[repeat(2,auto)] ",
        )}
      >
        <div
          className={classnames(
            textClassName,
            linksClassName,
            "col-span-3 mb-8 max-md:row-start-3 max-md:row-end-4 ",
          )}
        >
          <Typography.Heading3
            className="!text-lg  max-md:mb-5 md:!text-2xl"
            onDark
          >
            {t("explore.heading")}
          </Typography.Heading3>
          <Link className={linkHoverClassName} href={`${basePath}/pricing`}>
            {t("explore.pricing")}
          </Link>
          {/* <Link className={linkHoverClassName} href="">
                {t("explore.live_courses")}
              </Link>
              <Link className={linkHoverClassName} href="">
                {t("explore.artist_community")}
              </Link> */}
          <Link className={linkHoverClassName} href={`${basePath}/download`}>
            {t("explore.desktop_app")}
          </Link>
        </div>
        <div
          className={classnames(
            textClassName,
            linksClassName,
            "col-span-3 mb-8 max-md:row-start-3 max-md:row-end-4 ",
          )}
        >
          <Typography.Heading3
            className="!text-lg  max-md:mb-5 md:!text-2xl"
            onDark
          >
            {t("submix.heading")}
          </Typography.Heading3>
          <Link className={linkHoverClassName} href="https://help.submix.io">
            {t("submix.help_center")}
          </Link>
          <Link
            className={classnames(linkHoverClassName)}
            href={`${basePath}/privacy-policy`}
          >
            {t("privacy_policy")}
          </Link>
          <Link
            className={classnames(linkHoverClassName)}
            href={`${basePath}/terms-and-conditions`}
          >
            {t("terms_and_conditions")}
          </Link>
        </div>
        <div
          className={classnames(
            linksClassName,
            textClassName,
            "col-span-6 max-md:row-start-1",
            "max-md:flex-row max-md:justify-center max-md:!gap-7",
          )}
        >
          <Typography.Heading3
            className="hidden !text-lg  md:block md:!text-2xl"
            onDark
          >
            {t("social.heading")}
          </Typography.Heading3>
          <Link
            className={classnames(socialLinkClassName, linkHoverClassName)}
            href={t("twitter") || ""}
            target="_blank"
            aria-label="twitter"
          >
            <TwitterIconSmall
              className="max-md:h-6 max-md:w-6"
              width={16}
              height={16}
            />
            <Typography.Body onDark element="span">
              {t("social.twitter")}
            </Typography.Body>
          </Link>
          <Link
            className={classnames(
              socialLinkClassName,
              linkHoverClassName,
              "lg:min-w-[110px]",
            )}
            target="_blank"
            href={t("instagram") || ""}
            aria-label="instagram"
          >
            <InstagramIconSmall
              className="max-md:h-6 max-md:w-6"
              width={16}
              height={16}
            />
            <Typography.Body onDark element="span">
              {t("social.instagram")}
            </Typography.Body>
          </Link>
          <Link
            target="_blank"
            className={classnames(socialLinkClassName, linkHoverClassName)}
            href={t("facebook") || ""}
            aria-label="facebook"
          >
            <FacebookIconSmall
              className="max-md:h-6 max-md:w-6"
              width={16}
              height={16}
            />
            <Typography.Body onDark element="span">
              {t("social.facebook")}
            </Typography.Body>
          </Link>
          <Link
            className={classnames(socialLinkClassName, linkHoverClassName)}
            href={t("youtube") || ""}
            target="_blank"
            aria-label="youtube"
          >
            <YoutubeIconSmall
              className="max-md:h-6 max-md:w-6"
              width={16}
              height={16}
            />
            <Typography.Body onDark element="span">
              {t("social.youtube")}
            </Typography.Body>
          </Link>
          <Link
            className={classnames(socialLinkClassName, linkHoverClassName)}
            href={t("linkedin") || ""}
            target="_blank"
            aria-label="linkedin"
          >
            <LinkedinIconSmall
              className="max-md:h-6 max-md:w-6"
              width={16}
              height={16}
            />
            <Typography.Body onDark element="span">
              {t("social.linkedin")}
            </Typography.Body>
          </Link>
        </div>
        <div
          className={classnames(
            "flex flex-col",
            "col-span-6 row-start-2 md:col-span-12 md:row-start-1 lg:col-span-4 lg:col-start-9 lg:row-start-auto",
          )}
        >
          <GetLatestNews translation={t} basePath={basePath} />
        </div>
        <div
          className={classnames(
            "col-span-6 flex items-center gap-x-5 md:col-span-12",
            "max-md:flex-wrap max-md:justify-center",
          )}
        >
          <div className="h-0 max-md:mb-7 max-md:w-full" />
          <Link
            aria-label="Company Logo"
            href={basePath === "" ? "/" : basePath}
            className={classnames(
              "m-auto block w-[130px] max-md:mb-7 md:w-[190px] lg:m-0",
            )}
          >
            <Logo base />
          </Link>
          <Typography.Body
            onDark
            className={classnames(
              "pl-2 !text-sm !font-normal max-md:w-full max-md:text-center",
            )}
          >
            {t("submix_copyright")}
          </Typography.Body>
          <div className="md:grow" />
          <GrayLock width={22} height={23} className="max-md:order-first" />
          <MastercardIcon
            width={31}
            height={19}
            className="max-md:order-first"
          />
          <VisaIcon width={54} height={18} className="max-md:order-first" />{" "}
          <CbIcon width={30} height={23} className="max-md:order-first" />
        </div>
      </div>
    </footer>
  );
};
export default Footer;
