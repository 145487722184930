import { BaseSvgProps } from "./types";
const SvgCheckboxChecked = ({
  width,
  height,
  stroke = "#fff",
  className,
  strokeWidth = 2,
}: BaseSvgProps) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
      fill="black"
    />
    <path
      d="M4.42859 11.3286L6.76859 14.3371C6.84755 14.4397 6.94872 14.5231 7.06452 14.581C7.18031 14.6389 7.30771 14.6698 7.43716 14.6714C7.56453 14.6729 7.69062 14.646 7.80627 14.5926C7.92192 14.5392 8.02422 14.4607 8.10573 14.3628L15.5714 5.32855"
      stroke={stroke || "white"}
      strokeWidth={strokeWidth || "2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCheckboxChecked;
