"use client";

import type { InputRef } from "@submix/component-library";
import {
  Button,
  Input,
  Typography,
  useNotification,
} from "@submix/component-library";
import classnames from "classnames";
import { useCallback, useRef, useState } from "react";

const wrapperClassName = `
flex
max-w-[100%]
flex-col
bg-neutral-900`;

const whiteButtonClassName = `
w-[155px]
!bg-neutral-50
!text-neutral-900
!h-[44px]
!rounded
!rounded-full
!py-2.5
lg:mb-0
!border-neutral-50
max-[350px]:w-full
[&>span]:border-none
`;
const inputClassName = `
rounded
!border-neutral-500
!bg-white
text-sm
!text-black
placeholder:text-neutral-500 
focus-visible:border-neutral-500
focus-visible:!outline-neutral-500`;

const GetLatestNews = ({
  translation: t,
  basePath,
}: {
  translation: (key: string) => string;
  basePath: string;
}) => {
  const [error, setError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { addNotification } = useNotification();
  const emailRef = useRef<InputRef>(null);

  const handleSubscribe = useCallback(async () => {
    setIsLoading(true);
    const isEmailValid = emailRef?.current?.getValidity()?.valid;

    if (isEmailValid) {
      const response = await fetch(`${basePath}/account/api/newsletter`, {
        method: "POST",
        body: JSON.stringify({
          email: emailRef.current.getValue(),
        }),
      });
      if (!response || response.status !== 200) {
        if (response.status === 400) {
          setError({
            hasError: true,
            errorMessage: "newsletter.invalid_email",
          });
        } else {
          setError({
            hasError: true,
            errorMessage: "newsletter.subscription_fail",
          });
        }

        setIsLoading(false);
        return;
      }

      emailRef.current.setValue("");
      setError({
        hasError: false,
        errorMessage: "",
      });
      setIsLoading(false);
      addNotification({
        type: "success",
        text: {
          title: t("newsletter.success_newsletter"),
          description: t("newsletter.success_newsletter_description"),
        },
      });
      return;
    }
    setIsLoading(false);
    setError({ hasError: true, errorMessage: "newsletter.invalid_email" });
  }, [addNotification, basePath, t]);

  const resetTouch = () => setError({ hasError: false, errorMessage: "" });

  return (
    <section id="latest_news" className="scroll-mt-[70px] bg-neutral-50">
      <div className={wrapperClassName}>
        <div className="lg:mr-7">
          <Typography className="mb-5 !text-lg !font-bold leading-none !text-neutral-50 md:!text-2xl lg:mb-7">
            {t("get_the_latest_news")}
          </Typography>
          <Typography.Body className="mb-7 !text-neutral-50">
            {t("get_the_latest_news_text")}
          </Typography.Body>
        </div>
        <div className="flex max-w-[100%] items-center gap-2.5 max-[350px]:flex-col">
          <div className="relative w-full">
            <Input
              ref={emailRef}
              className={classnames(
                inputClassName,
                "peer",
                error.hasError && "invalid:border-2 invalid:!border-negative",
              )}
              placeholder={t("your_email_address_placeholder") || ""}
              pattern="[a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?"
              required
              onBlur={resetTouch}
            />
            <div className="absolute top-[-26px] flex flex-row">
              <Typography.ErrorMessage
                className={`${
                  error.hasError ? "visible" : "invisible"
                } block min-h-[25px] lg:min-h-[28px] ${
                  error.hasError ? "peer-invalid:visible" : ""
                }`}
              >
                {t(error.errorMessage) || ""}
              </Typography.ErrorMessage>
            </div>
          </div>
          <Button
            loading={isLoading}
            variant="tertiary"
            color="black"
            onClick={handleSubscribe}
            className={whiteButtonClassName}
          >
            {t("subscribe")}
          </Button>
        </div>
        <Typography.Body className="mr-7 pt-2.5 !text-sm !leading-5 !text-neutral-500">
          {t("subscribe_terms_message")}
        </Typography.Body>
      </div>
    </section>
  );
};
export default GetLatestNews;
